@media screen and (max-width: 1280px) {
  .order-list li {
    padding: 20px 30px;
  }
  .services-details {
    padding: 10px 5px 10px;
  }
  .services .btn {
    padding: 8px 20px 9px;
  }
  .services .services-title {
    font-size: 25px;
  }
  .services-list li {
    min-height: 270px;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 0;
  }
  .header-menu a {
    font-size: 20px;
  }
  .header-phone a {
    font-size: 16px;
  }
  .header-mobile {
    width: auto;
  }
  .services-list li {
    width: 47%;
  }
  .footer-wrapper {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .footer-col {
    padding: 0 5px;
  }
}

@media screen and (max-width: 992px) {
  .steps-list li:not(:last-child):before {
    width: 65%;
  }
}

@media screen and (max-width: 767px) {
  .btn {
    padding: 8px 20px 9px;
    font-size: 19px;
  }
  h2 {
    font-size: 28px;
  }
  .up-btn {
    background: #fff;
    width: 50px;
    height: 50px;
    right: 10px;
  }
  .up-btn:before {
    top: 19px;
    left: 12px;
  }
  .header-logo a img {
    max-height: 80px;
  }
  .header-burger {
    display: block;
  }
  .header-mobile {
    position: absolute;
    display: none;
    top: 80px;
    right: 0;
    background: #fff;
    width: 100%;
    height: calc(100vh - 80px);
    transition: .3s ease-in-out;
  }
  .header-mobile.open {
    right: 0;
  }
  .header-menu ul {
    flex-direction: column;
  }
  .header-menu a {
    font-size: 24px;
    line-height: 2;
  }
  .header-lang {
    text-align: center;
  }
  .header-phone {
    justify-content: center;
    padding-top: 15px;
  }
  .header-phone a {
    font-size: 18px;
  }
  .menu {
    display: none;
  }
  .banner {
    margin-top: 80px;
  }
  .banner-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .banner-wrapper h1 {
    line-height: 1.2;
    font-size: 36px;
  }
  .banner-left {
    padding: 0 15px 30px 15px;
  }
  .banner-left p {
    padding-bottom: 0;
  }
  .banner-left .btn {
    display: none;
  }
  .banner-driver .banner-wrapper, .banner-establishment .banner-wrapper {
    padding: 15px 0;
  }
  .banner-driver .banner-wrapper .bigger, .banner-establishment .banner-wrapper .bigger {
    font-size: 18px;
  }
  .order-list {
    flex-wrap: wrap;
  }
  .order-list li {
    width: 100%;
  }
  .order-list li:not(:last-child) {
    border-right: 0;
    border-bottom: 1px solid #b8babc;
  }
  .order-list li a {
    justify-content: center;
  }
  .problems-list {
    flex-wrap: wrap;
  }
  .problems-list li {
    max-width: 45%;
    margin-bottom: 30px;
  }
  .advantages {
    padding: 35px 0 60px;
  }
  .advantages-list li {
    max-width: 45%;
  }
  .steps {
    padding: 40px 0 45px;
  }
  .steps-list {
    flex-wrap: wrap;
  }
  .steps-list li:not(:last-child):before {
    display: none;
  }
  .sales-slider {
    padding: 0 30px;
  }
  .sales-slider .slide-text {
    font-size: 25px;
    width: 100%;
  }
  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 0px;
  }
  .services-list li {
    width: 100%;
  }
  .services-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .services-details {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .reasons-title {
    font-size: 23px;
  }
  .reasons-list li {
    font-size: 16px;
  }
  .reasons-icon {
    width: 110px;
  }
  .reasons-desc, .reasons-title {
    width: calc(100% - 130px);
  }
  .faq .toggle-dropdown {
    font-size: 18px;
    padding: 20px;
  }
  .faq .toggle-dropdown:before {
    width: 20px;
    height: 20px;
    right: 20px;
    top: 17px;
  }
  .faq .toggle-dropdown.open:before {
    top: 30px;
  }
  .faq .dropdown {
    padding: 0px 20px 20px;
  }
  .reviews-text {
    font-size: 16px;
    padding-bottom: 20px;
  }
  .reviews-text p {
    margin: 0;
  }
  .reviews .slick-prev {
    left: 0;
  }
  .reviews .slick-next {
    right: 0;
  }
  .question {
    padding: 10px 0 30px;
  }
  .question-desc {
    font-size: 17px;
  }
  .question-field {
    width: 100%;
  }
  .question-label {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .question input {
    padding: 20px 15px 20px;
  }
}

@media screen and (max-width: 479px) {
  .banner .order-form {
    padding: 20px 15px;
    width: 300px;
  }
  .banner-wrapper {
    font-size: 19px;
  }
  .banner-wrapper h1 {
    font-size: 31px;
  }
  .problems-list li {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .advantages-list li {
    max-width: 100%;
  }
  .reasons-list li {
    text-align: center;
  }
  .reasons-icon {
    width: 150px;
    float: none;
    margin: 0 auto;
    padding-bottom: 20px;
    display: block;
  }
  .reasons-desc, .reasons-title {
    width: 100%;
  }
  .sales-slider .slide-text {
    font-size: 18px;
  }
  .footer {
    padding: 20px 0;
  }
  .footer-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-col {
    text-align: center;
  }
}
