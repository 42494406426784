@media screen and (max-width: 1920px){

}
@media screen and (max-width: 1600px){

}
@media screen and (max-width: 1366px){

}
@media screen and (max-width: 1280px){
	.order-list li {
	    padding: 20px 30px;
	}
	.services {
		&-details {
			padding: 10px 5px 10px;
		}
		.btn {
			padding: 8px 20px 9px; 
		}
		.services-title {
			font-size: 25px;
		}
		&-list {
			li {
				min-height: 270px;
			}
		}
	}
}
@media screen and (max-width: 1024px){
	.header {
		padding: 0;

		&-menu {
			a {
				font-size: 20px;
			}
		}

		&-phone {
			a {
				font-size: 16px;
			}
		}

		&-mobile {
			width: auto;
		}
	}
	.services-list li {
		width: 47%;
	}
	.footer {
		&-wrapper {
			flex-wrap: wrap;
		    justify-content: space-around;
		}
		&-col {
			padding: 0 5px;
		}
	}
}
@media screen and (max-width: 992px){
	.steps-list li:not(:last-child):before {
		width: 65%;
	}
}
@media screen and (max-width: 767px){
	.btn {
		padding: 8px 20px 9px;
		font-size: 19px;
	}

	h2 {
		font-size: 28px;
	}

	.up-btn {
		background: #fff;
		width: 50px;
		height: 50px;
		right: 10px;

		&:before {
			top: 19px;
    		left: 12px;
		}
	}

	.header {
		&-logo {
			a {
				img {
					max-height: 80px;
				}
			}
		}
		&-burger {
			display: block;
		}
		&-mobile {
			position: absolute;
			display: none;
			top: 80px;
			right: 0;
			background: #fff;
			width: 100%;
			height: calc(100vh - 80px);
			transition: .3s ease-in-out;

			&.open {
				right: 0;
			}
		}
		&-menu {
			ul {
				flex-direction: column;
			}
			a {
				font-size: 24px;
				line-height: 2;
			}
		}
		&-lang {
			text-align: center;
		}
		&-phone {
			justify-content: center;
    		padding-top: 15px;

    		a {
    			font-size: 18px;
    		}
		}
	}

	.menu {
		display: none;
	}

	.banner {
		margin-top: 80px;

		&-wrapper {
			flex-direction: column;
    		align-items: center;

    		h1 {
    			line-height: 1.2;
    			font-size: 36px;
    		}
		}

		&-left {
			padding: 0 15px 30px 15px;

			p {
				padding-bottom: 0;
			}

			.btn {
				display: none;
			}
		}

		&-driver,
		&-establishment {
			.banner-wrapper {
				padding: 15px 0;

				.bigger {
					font-size: 18px;
				}
			}
		}
	}
	
	.order-list {
		flex-wrap: wrap;

		li {
		    width: 100%;

		    &:not(:last-child) {
		    	border-right: 0;
		    	border-bottom: 1px solid #b8babc;
		    }

		    a {
		    	justify-content: center;
		    }
		}
	}

	.problems-list {
		flex-wrap: wrap;

		li {
			max-width: 45%;
			margin-bottom: 30px;
		}
	}

	.advantages {
		padding: 35px 0 60px;

		&-list li {
			max-width: 45%;
		}
	}

	.steps {
		padding: 40px 0 45px;

		&-list {
			flex-wrap: wrap;

			li {
				&:not(:last-child) {
					&:before {
						display: none;
					}
				}
			}
		}
	}

	.sales-slider {
		padding: 0 30px;

		.slide-text {
			font-size: 25px;
			width: 100%;
		}
	}

	.slick-prev {
	    left: 0px;
	}

	.slick-next {
	    right: 0px;
	}

	.services {
		&-list {
			li {
				width: 100%;
			}
		}
		&-info {
			display: flex;
		    flex-direction: column;
		    justify-content: center;
		}
		&-details {
			display: flex;
		    justify-content: center;
		    flex-direction: column;
		}
	}

	.reasons {
		&-title {
			font-size: 23px;
		}
		&-list {
			li {
				font-size: 16px;
			}
		}
		&-icon {
			width: 110px;
		}
		&-desc,
		&-title {
			width: calc(100% - 130px);
		}
	}
	.faq {
		.toggle-dropdown {
			font-size: 18px;
			padding: 20px;

			&:before {
				width: 20px;
			    height: 20px;
			    right: 20px;
			    top: 17px;
			}

			&.open:before {
			    top: 30px;
			}
		}
		.dropdown {
		    padding: 0px 20px 20px;
		}
	}
	.reviews {
		&-text {
			font-size: 16px;
			padding-bottom: 20px;

			p {
				margin: 0;
			}
		}
		.slick-prev {
		    left: 0;
		}
		.slick-next {
		    right: 0;
		}
	}
	.question {
		padding: 10px 0 30px;

		&-desc {
			font-size: 17px;
		}
		&-field {
			width: 100%;
		}
		&-label {
			font-size: 18px;
			padding-bottom: 10px;
		}
		input {
			padding: 20px 15px 20px;
		}
	}
}

@media screen and (max-width: 479px){
	.banner {
		.order-form {
			padding: 20px 15px;
			width: 300px;
		}
		&-wrapper {
			font-size: 19px;

			h1 {
				font-size: 31px;
			}
		}
	}
	.problems-list {
		li {
			max-width: 100%;
			margin-bottom: 20px;
		}
	}

	.advantages-list li {
		max-width: 100%;
	}
	.reasons {
		&-list {
			li {
				text-align: center;
			}
		}
		&-icon {
			width: 150px;
			float: none;
			margin: 0 auto;
			padding-bottom: 20px;
			display: block;
		}
		&-desc,
		&-title {
			width: 100%;
		}
	}
	.sales-slider .slide-text {
		font-size: 18px;
	}
	.footer {
		padding: 20px 0;

		&-wrapper {
		    flex-direction: column;
		    justify-content: center;
		    align-items: center;
		}
		&-col {
			text-align: center;
		}
	}
}
